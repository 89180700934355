import { transformMedicationsResponse } from "./transformMedicationsResponse"
import {
  useRxDBSimpleLoader,
  useLoadRxDBCollection,
} from "src/hooks/rxdb"
import { medicationCollectionKey } from "src/schema"

export function useLoadMedicationsCollection() {
  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadingState,
    loader,
    resetLoader,
  ] = useRxDBSimpleLoader(
    "meds",
    "/api/v3/meds",
    transformMedicationsResponse,
  )

  useLoadRxDBCollection(
    medicationCollectionKey,
    loader,
    resetLoader,
  )
}
